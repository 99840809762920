<template>
  <div class="box px-5 pt-5 mt-5" :class="{ 'intro-y': hasAnimation }">
    <div
      class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
    >
      <div class="flex flex-1 px-5 items-start justify-center lg:justify-start">
        <div
          class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
        >
          <div
            v-if="displayAvatarActions"
            class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"
            @click="onClickConfirmDelete"
          >
            <xIcon class="cursor-pointer w-4 h-4" />
          </div>

          <VImage
            :src="avatarURL"
            class="flex items-center justify-center"
            :color="color"
            :width="32"
            :height="32"
            :name="`${user?.firstname} ${user?.lastname}`"
            name-class="text-3xl"
          />

          <div
            v-if="displayAvatarActions"
            class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-1 rounded-full p-2"
          >
            <CameraIcon class="cursor-pointer w-4 h-4 text-white" />

            <input
              :value="file"
              type="file"
              class="w-full h-full top-0 left-0 absolute opacity-0"
              @input="onInput"
            />
          </div>
        </div>

        <div class="ml-5">
          <div
            class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
          >
            {{ getAttribute("firstname") }} {{ getAttribute("lastname") }}
          </div>

          <div class="text-gray-600">@{{ getAttribute("username") }}</div>
        </div>
      </div>

      <div
        class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center lg:text-left lg:mt-3">
          {{ $t("app.contact_details") }}
        </div>

        <div
          class="flex flex-col justify-center items-center lg:items-start mt-4"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            <MailIcon class="w-4 h-4 mr-2" />
            {{ getAttribute("email") }}
          </div>

          <div class="truncate sm:whitespace-normal flex items-center mt-3">
            <PhoneIcon class="w-4 h-4 mr-2" />
            {{ getAttribute("phone") }}
          </div>
        </div>
      </div>

      <div
        class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center lg:text-left lg:mt-3">
          {{ $t("app.license_details") }}
        </div>

        <div
          class="flex flex-col justify-center items-center lg:items-start mt-4"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ getAttribute("license.name") }}
          </div>

          <div class="truncate sm:whitespace-normal flex items-center mt-3">
            {{ licenseDate }}
          </div>
        </div>
      </div>

      <div class="mt-6 lg:mt-0 flex-1 px-5 pt-5 lg:pt-0" />
    </div>

    <slot name="tabs">
      <div class="flex justify-between">
        <VTabs
          :current-tab="selectedTab"
          :tabs="tabs.slice(0, -1)"
          @click="onClickRedirect"
        />

        <VTabs
          :current-tab="selectedTab"
          :tabs="tabs.slice(-1)"
          @click="onClickRedirect"
        />
      </div>
    </slot>

    <DeleteModal
      v-if="isModalVisible"
      id="modal"
      :message="$t('app.delete_profile_avatar')"
      :is-deleting="isDeleting"
      @click:cancel="onClickCancel"
      @click:delete="$emit('delete:avatar')"
    />
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
// Composables
import useTabs from "@/composables/useTabs";
import useUser from "@/composables/useUser";
import useModal from "@/composables/useModal";
import useDisplay from "@/composables/useDisplay";
import useAnimation from "@/composables/useAnimation";
import useColor from "@/composables/useColor";
// Components
import DeleteModal from "@/components/modals/DeleteModal";
import VTabs from "@/components/VTabs";
import VImage from "@/components/VImage";

export default {
  components: {
    VTabs,
    VImage,
    DeleteModal
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    avatar: {
      type: String,
      default: ""
    },
    isDeleting: {
      type: Boolean,
      default: false
    },
    displayAvatarActions: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // MISC
    const store = useStore();
    const { t } = useI18n();
    const currentRoute = useRoute();
    const router = useRouter();
    const moment = inject("moment");
    const _ = inject("lodash");

    // Composables
    const { getAvatarURL } = useDisplay();
    const { isModalVisible, showModal, hideModal } = useModal();
    const { buildTab } = useTabs();
    const { route } = useUser();
    const { hasAnimation } = useAnimation();
    const { getRandomColors } = useColor();

    // DATA
    const file = ref("");

    // COMPUTED
    const color = computed(() => getRandomColors()[0]);
    const avatarURL = computed(() => getAvatarURL(props.avatar));
    const dateFormat = computed(() => store.getters["app/date_format"]);

    const licenseDate = computed(() => {
      const value = props.user?.license?.expiry_date;
      const date = value ? moment(value).format(dateFormat.value) : "";

      if (!date) {
        return t("app.perpetual_license");
      }

      return t("app.expires_on", { date });
    });

    const selectedTab = computed(() => {
      const allTabs = tabs.value.reduce((acc, tab) => {
        return {
          ...acc,
          [`${route}-${tab.name}`]: tab.name
        };
      }, {});

      return allTabs[currentRoute.name] ?? "";
    });

    const tabs = computed(() => [
      buildTab({ name: "details" }),
      buildTab({ name: "memberships" }),
      buildTab({ name: "logs" })
    ]);

    // METHODS
    const onClickRedirect = tab => {
      router.push({
        name: `${route}-${tab}`,
        params: {
          id: props.user?.id
        }
      });
    };

    const getAttribute = (attr, fallback = "") => {
      return _.get(props.user, attr, fallback);
    };

    const onClickConfirmDelete = () => {
      showModal();
    };

    const onInput = e => {
      emit("update:avatar", e);
    };

    return {
      color,
      onClickRedirect,
      tabs,
      avatarURL,
      licenseDate,
      getAttribute,
      onClickConfirmDelete,
      onClickCancel: hideModal,
      onInput,
      file,
      selectedTab,
      // useAnimation
      hasAnimation,
      // useModal
      isModalVisible
    };
  }
};
</script>
