<template>
  <UserView>
    <UserHeader
      v-if="data"
      :user="data"
      :avatar="data?.avatar?.view_path"
      class="mb-10"
    />

    <router-view
      :user="data"
      :is-loading="isLoading"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
      @click:logs="onClickLogs"
      @click:regenerate="onClickRegenerate"
      @click:anonymize="onClickAnonymize"
      @update:license="onUpdateLicense"
      @refresh="getData"
      @create:department="onCreateMembership($event, 'departments')"
      @create:site="onCreateMembership($event, 'sites')"
      @create:role="onCreateMembership($event, 'roles')"
      @delete:department="
        onDeleteMembership($event.deletable.id, 'departments', $event.hideModal)
      "
      @delete:site="
        onDeleteMembership($event.deletable.id, 'sites', $event.hideModal)
      "
      @delete:role="
        onDeleteMembership($event.deletable.id, 'roles', $event.hideModal)
      "
    />
  </UserView>
</template>

<script>
import { provide, ref } from "vue";
import { useStore } from "@/store";
// Composables
import useUser from "@/composables/useUser";
import useRequest from "@/composables/useRequest";
import useUpdate from "@/composables/useUpdate";
import useSearch from "@/composables/useSearch";
// Components
import UserHeader from "@/views/administration/users/UserHeader";
import UserView from "@/views/administration/users/UserView";

export default {
  components: {
    UserHeader,
    UserView
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // MISC
    const store = useStore();

    // Provide
    provide("view_code", view_code);
    provide("types", types);

    // CONSTANTS
    const relations = ["license", "departments", "sites", "roles", "avatar"];

    // DATA
    const isRegenerating = ref(false);
    const isDeleting = ref(false);
    const isAdding = ref({
      roles: false,
      departments: false,
      sites: false
    });
    provide("isDeleting", isDeleting);
    provide("isAdding", isAdding);
    provide("isRegenerating", isRegenerating);

    // CUSTOM COMPOSABLES
    const { request } = useRequest();
    const {
      logType,
      searchFields,
      view_code,
      route,
      types,
      endpoint
    } = useUser();
    const {
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport
    } = useSearch({
      types,
      searchFields,
      route,
      endpoint
    });
    const {
      data,
      isLoading,
      getData,
      onClickSave,
      onClickCancel,
      onClickLogs,
      onClickCreate
    } = useUpdate({
      logType,
      endpoint,
      route,
      id: props.id,
      relations
    });

    // METHODS
    const updateLicense = async license_id => {
      await request({
        endpoint: "administration.users.update",
        pathParams: { id: props.id },
        data: { license_id }
      });
    };

    const onUpdateLicense = async license_id => {
      await updateLicense(license_id);
      await getData();
    };

    const deleteMembership = async (id, entity) => {
      isDeleting.value = true;

      await request({
        endpoint: `administration.${entity}.users.delete`,
        pathParams: {
          id,
          user_id: props.id
        }
      });

      isDeleting.value = false;
    };

    const onDeleteMembership = async (id, entity, hideModal) => {
      await deleteMembership(id, entity);
      hideModal();
      await getData();
    };

    const createMembership = async (id, entity) => {
      isAdding.value[entity] = true;

      await request({
        endpoint: `administration.${entity}.users.create`,
        pathParams: { id },
        data: { user_id: props.id }
      });

      isAdding.value[entity] = false;
    };

    const onCreateMembership = async (id, entity) => {
      if (!id) {
        return;
      }

      await createMembership(id, entity);
      await getData();
    };

    const regenerate = async () => {
      isRegenerating.value = true;

      const response = await request({
        endpoint: "administration.users.regenerate",
        pathParams: { id: props.id }
      });

      const isMyself = props.id == store.getters["user/id"];
      const api_key = response?.payload?.data?.api_key;

      if (api_key && isMyself) {
        store.dispatch("user/setApiToken", api_key);
      }

      isRegenerating.value = false;
    };

    const onClickRegenerate = async () => {
      await regenerate();
      await getData();
    };

    const anonymize = async () => {
      await request({
        endpoint: "administration.users.anonymize",
        pathParams: { id: props?.id }
      });
    };

    const onClickAnonymize = async () => {
      await anonymize();
      await getData();
    };

    return {
      onClickAnonymize,
      onUpdateLicense,
      onDeleteMembership,
      onCreateMembership,
      onClickRegenerate,
      // useUser
      searchFields,
      // useSearch
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport,
      // useUpdate
      getData,
      data,
      isLoading,
      onClickSave,
      onClickCancel,
      onClickLogs,
      onClickCreate
    };
  }
};
</script>
